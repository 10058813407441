.tdsSelect {
  margin-bottom: 1rem;
}
.tdsSelect .select {
  font-family: Arial, sans-serif;
}
.tdsSelect .select__label {
  font-family: Arial, sans-serif;
  color: #333333;
  font-weight: bold;
}
.tdsSelect .select__label--error, .tdsSelect .select__message--error {
  color: #e51837;
}
.tdsSelect .select__container--error::after {
  background-color: #e51837;
}
.tdsSelect.required-field .select__label:after {
  content: "*";
  color: #e51837;
  margin-left: 2px;
}
.tdsSelect .select__list {
  font-family: Arial, sans-serif;
}
