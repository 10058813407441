.consentForm .consent-text {
  max-width: 800px;
}
.consentForm [class^=consent-section] {
  padding: 0 1rem 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #DDDDDD;
}
.consentForm [class^=consent-section] .checkbox {
  margin-top: 1rem;
}
.consentForm .consent-section0 {
  margin-top: 2rem;
  padding-bottom: 0;
  border-bottom: none;
}
.consentForm .last-consent-section {
  border-bottom: none;
}
