.payment-container {
  padding: 10px;
  background-color: #f0f0f0;
}

.payment-ui .form-control .form-control:invalid,
.payment-ui .form-control .form-control:valid, .payment-ui .form-control:valid .form-control:invalid,
.payment-ui .form-control:valid .form-control:valid,
.payment-ui .form-control:invalid .form-control:invalid,
.payment-ui .form-control:invalid .form-control:valid, .was-validated .payment-ui .form-control:invalid,
.was-validated .payment-ui .form-control:valid {
  border-color: #DDDDDD !important;
}
.payment-ui .invalid-feedback {
  font-size: 1em;
}
.payment-ui label {
  font-weight: 700;
}
.payment-ui .form-row > div {
  padding-left: 5px;
  padding-right: 5px;
}
.payment-ui .gmpayment-cvv-field {
  width: 70px;
}
.payment-ui .gmpayui-card-type-image {
  width: 53px;
  height: auto;
  vertical-align: middle;
  margin-left: 4px;
}
@media (min-width: 576px) {
  .payment-ui .gmpayment-name-field,
  .payment-ui .gmpayment-creditcard-field {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
