.contactus-form .textField__label:after {
  content: "*";
  color: #e51837;
  margin-left: 2px;
}
.contactus-form .comments-label {
  font-weight: 700;
  color: #333333;
}
.contactus-form .comments-label:after {
  content: "*";
  color: #e51837;
  margin-left: 2px;
}
.contactus-form .invalid-feedback {
  font-size: 1em;
}
.contactus-form .fixed-height-text {
  height: 200px;
  overflow-y: auto;
}
.contactus-form .form-inputs {
  width: 45%;
  margin-top: 6px;
  margin-bottom: 6px;
}
.contactus-form .comments {
  margin-top: 6px;
  margin-bottom: 6px;
}
.contactus-form .header {
  margin-bottom: 20px;
}
.contactus-form .message {
  padding-bottom: 20px;
}
.contactus-form .a {
  padding-left: 50px;
}
.contactus-form .delcoNumber {
  margin-top: 15px;
  color: #005dab;
  font-weight: lighter;
  font-size: 2.1875rem;
}
.contactus-form .chinaDelcoInfo {
  color: #005dab;
  font-weight: lighter;
  font-size: 1.5rem;
}
