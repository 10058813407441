.vehicle-form .order-summary {
  min-width: auto;
  margin-bottom: 2em;
  z-index: 0;
}
@media (min-width: 860px) {
  .vehicle-form .order-summary {
    min-width: 25rem;
  }
}
