.orderSummary {
  background-color: #f0f0f0;
}
.orderSummary .orderSummaryTitle {
  background-color: #005dab;
  color: #fff;
  text-align: center;
  padding: 4px;
  text-transform: uppercase;
}
.orderSummary .orderSummaryBody {
  padding: 20px;
}
.orderSummary .promo-row {
  color: #53872f;
}
.orderSummary.basic {
  background-color: transparent;
}
.orderSummary.basic .orderSummaryTitle {
  display: none;
}
.orderSummary.basic .orderSummaryBody {
  padding: 10px 0;
}
