.keycode-confirmation .divIcon {
  position: relative;
}
.keycode-confirmation .sideIcon {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  width: 3rem;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  left: 0;
  padding-top: 8px;
  position: absolute;
  top: 0;
}
.keycode-confirmation .gray-background {
  background: #f0f0f0;
}

.keycode-activate p {
  margin-bottom: 0;
}
.keycode-activate .countdown-message i {
  float: left;
}
.keycode-activate .countdown-message .overflow-hidden {
  overflow: hidden;
}
