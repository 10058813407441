.purchaseKeycodesWizard {
  max-width: 62.5rem;
}
.purchaseKeycodesWizard .shortLine {
  display: inline-block;
  width: 50px;
  height: 0;
  border-top: 1px solid #555555;
  overflow: visible;
  box-sizing: content-box;
  margin: 0.5em 1em;
}
@media (max-width: 768px) {
  .purchaseKeycodesWizard .shortLine {
    display: none;
  }
}
.purchaseKeycodesWizard .progressWrapper {
  display: inline-block;
}
@media (max-width: 768px) {
  .purchaseKeycodesWizard .progressWrapper {
    width: 100%;
  }
}
.purchaseKeycodesWizard .stepName {
  font-size: 1rem;
  color: #555555;
  margin-left: 4px;
}
.purchaseKeycodesWizard .wizard-progress {
  margin-bottom: 50px;
  margin-top: 28px;
}
.purchaseKeycodesWizard .fa-layers {
  color: #555555;
}
.purchaseKeycodesWizard .fa-layers .fa-layers-text {
  margin-top: 0.375rem;
  font-size: 0.875rem;
}
.purchaseKeycodesWizard .fa-layers.active {
  color: #005dab;
}
.purchaseKeycodesWizard .fa-check-circle {
  color: #555555;
  vertical-align: -0.125em;
}
