.appFooter {
  background: #f0f0f0;
  padding: 15px;
}
.appFooter a {
  color: #555555;
  padding: 10px;
  white-space: nowrap;
}
.appFooter i {
  color: #555555;
  font-size: 5px;
  position: relative;
  vertical-align: middle;
}
.appFooter .business-entity {
  padding-right: 10px;
  color: #333333;
}
