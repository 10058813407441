div.dropdown-container.show > div.dropdown-list {
  transform: scale(1, 1);
}
div.dropdown-container.show i {
  transform: rotate(180deg);
  transition: all 0.25s ease-out;
}
div.dropdown-container i {
  transform: rotate(0deg);
  transition: all 0.25s ease-in;
}
div.dropdown-container > div.dropdown-display {
  float: left;
  width: 100%;
  height: auto;
  cursor: pointer;
  box-sizing: border-box;
  padding: 0.25rem 0.5rem;
}
div.dropdown-container > div.dropdown-display > span {
  width: 100%;
  position: relative;
  box-sizing: border-box;
}
div.dropdown-container > div.dropdown-display > span i {
  margin-left: 0.5rem;
}
div.dropdown-container > div.dropdown-list {
  position: absolute;
  z-index: 2;
  transform: scale(1, 0);
  -webkit-transform: scale(1, 0);
  transition: transform ease 250ms;
}
div.dropdown-container > div.dropdown-list > div {
  cursor: pointer;
  padding: 0.5rem 0;
  transform: translateY(2.25rem) translateX(0rem) scaleY(1);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  background: #fff;
  float: left;
}
div.dropdown-container > div.dropdown-list > div > div {
  float: left;
  width: 100%;
  margin-left: 0 !important;
  padding: 0 1rem;
}
div.dropdown-container > div.dropdown-list > div > div:hover {
  outline: 0;
  background: rgba(0, 0, 0, 0.08);
}
div.dropdown-container > div.dropdown-list > div > div > span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #666666;
  overflow: hidden;
  line-height: 1.5em;
  white-space: nowrap;
  height: 2rem;
}
