.tds_h1 {
  font-family: Futura-Medium;
  font-size: 1.875rem;
  font-weight: 500;
  color: #005dab;
  text-transform: uppercase;
}

.tds_h2 {
  font-family: Futura-Medium;
  font-size: 1.125rem;
  font-weight: 500;
  text-transform: uppercase;
  color: #333333;
}

.tds_h3 {
  font-family: Futura-Medium;
  font-size: 1.125rem;
  font-weight: 500;
  color: #333333;
}

.tds_h4 {
  font-family: Futura-Medium;
  font-size: 1.09375rem;
  color: #333333;
}
