.dropdown {
  /*position: relative;*/
}
.dropdown .dropdown-button {
  padding: 0 !important;
}
.dropdown .dropdown-button.show i {
  transform: rotate(180deg);
  transition: all 0.25s ease-out;
}
.dropdown i {
  transform: rotate(0deg);
  transition: all 0.25s ease-in;
  margin-left: 0.5rem;
}
.dropdown .dropdown-menu {
  position: absolute;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  background: #fff;
  padding: 0.5rem 0;
}
@media screen and (min-width: 600px) {
  .dropdown .dropdown-menu {
    right: 0;
  }
}
.dropdown .dropdown-menu .dropdown-item {
  padding: 0.15rem 1rem;
  overflow: hidden;
  white-space: nowrap;
}
.dropdown .dropdown-menu .dropdown-item:hover {
  outline: 0;
  background: rgba(0, 0, 0, 0.08);
}
