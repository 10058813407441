.cart-container {
  position: relative;
}
.cart-container .badge {
  position: absolute;
}
.cart-container .panel--floating {
  right: 0;
}
.cart-container .cart-panel {
  background-color: #f0f0f0;
  color: #333333;
  min-width: 15.625rem;
}
.cart-container .cart-panel .cart-list {
  width: 15.625rem;
}
.cart-container .cart-panel .cart-list .cart-subtotal {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 0.625rem;
}

.shopping-cart-page input {
  border: 0;
  background: transparent !important;
  text-align: center;
  border-bottom: #DDDDDD 1px solid;
}
.shopping-cart-page input:focus {
  outline: none;
}
.shopping-cart-page input:hover {
  border-bottom: #000000 1px solid;
}
.shopping-cart-page input:focus {
  border-bottom: #1976d2 1px solid;
}
.shopping-cart-page .cart-list {
  max-width: 56.25rem;
}
@media (max-width: 56.25rem) {
  .shopping-cart-page .cart-list {
    max-width: 100%;
  }
}
.shopping-cart-page .cart-list .cart-subtotal {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 0.625rem;
  padding-bottom: 1rem;
}
.shopping-cart-page .cart-list .tdsInput {
  margin-bottom: 0 !important;
}
.shopping-cart-page .cart-list .tdsInput .textField__container {
  padding-top: 0;
}
.shopping-cart-page .cart-list .tds-quantity {
  min-width: 55px;
}
