.login-container .panel--floating {
  margin-right: 5px;
}
.login-container .close-btn {
  padding: 0 !important;
  margin-left: 1rem;
}
.login-container .login-panel {
  background-color: #f0f0f0;
}
.login-container .login-panel form {
  width: 225px;
}
.login-container h1 {
  text-transform: uppercase;
  font-size: 1rem;
}
