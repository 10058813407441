.text-blue {
  color: #005dab;
}

.text-gray {
  color: #555555;
}

.text-red {
  color: #e51837;
}

.no-shadow {
  box-shadow: none;
}

.itemSubscriptions {
  margin-bottom: 1rem;
  border: 0;
}
.itemSubscriptions .list {
  padding: 0 15px;
}
.itemSubscriptions .list .list__list .list__item {
  padding: 0;
}
@media (max-width: 768px) {
  .itemSubscriptions .list .list__list .list__item:last-child {
    padding-bottom: 10px;
  }
  .itemSubscriptions .list .list__list .list__item:first-child {
    padding-top: 10px;
  }
}
.itemSubscriptions .list .list__list :hover {
  background-color: transparent;
}
.itemSubscriptions .tdsSelector {
  margin-bottom: 0;
}

.itemHeader {
  margin-top: 2em;
  margin-bottom: 1rem;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 5px;
}

.renew-subs-modal .container {
  margin: 0;
}
.renew-subs-modal .modal__contentContainer {
  overflow: visible;
}
@media screen and (max-width: 575px) {
  .renew-subs-modal .modal__contentContainer {
    min-width: 90vw;
  }
}
@media screen and (min-width: 576px) {
  .renew-subs-modal .modal__contentContainer {
    min-width: 60vw;
  }
}
@media (min-width: 768px) {
  .renew-subs-modal .modal__contentContainer {
    min-width: 50vw;
  }
}
@media (min-width: 1200px) {
  .renew-subs-modal .modal__contentContainer {
    min-width: 40vw;
  }
}
.renew-subs-modal .modal__content {
  overflow: visible;
}

.vin-subscriptions-modal .container {
  margin: 0;
  padding: 0;
}
.vin-subscriptions-modal .modal__content {
  overflow: visible;
}
.vin-subscriptions-modal .modal__contentContainer {
  min-width: 500px;
}

.addToCartConfirmModal .modal__contentContainer {
  max-width: 500px;
  padding-top: 45px;
  padding-botton: 0px;
}
