.fa {
  font-family: var(--fa-style-family, "Font Awesome 6 Free");
  font-weight: var(--fa-style, 900);
}

.fa,
.fa-classic,
.fa-sharp,
.fas,
.fa-solid,
.far,
.fa-regular,
.fab,
.fa-brands {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
}

.fas,
.fa-classic,
.fa-solid,
.far,
.fa-regular {
  font-family: "Font Awesome 6 Free";
}

.fab,
.fa-brands {
  font-family: "Font Awesome 6 Brands";
}

.cart-container {
  position: relative;
}
.cart-container .badge {
  position: absolute;
}
.cart-container .panel--floating {
  right: 0;
}
.cart-container .cart-panel {
  background-color: #f0f0f0;
  color: #333333;
  min-width: 15.625rem;
}
.cart-container .cart-panel .cart-list {
  width: 15.625rem;
}
.cart-container .cart-panel .cart-list .cart-subtotal {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 0.625rem;
}

.shopping-cart-page input {
  border: 0;
  background: transparent !important;
  text-align: center;
  border-bottom: #DDDDDD 1px solid;
}
.shopping-cart-page input:focus {
  outline: none;
}
.shopping-cart-page input:hover {
  border-bottom: #000000 1px solid;
}
.shopping-cart-page input:focus {
  border-bottom: #1976d2 1px solid;
}
.shopping-cart-page .cart-list {
  max-width: 56.25rem;
}
@media (max-width: 56.25rem) {
  .shopping-cart-page .cart-list {
    max-width: 100%;
  }
}
.shopping-cart-page .cart-list .cart-subtotal {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 0.625rem;
  padding-bottom: 1rem;
}

.notification-item {
  border-top: #DDDDDD 1px solid;
  padding: 0.5rem;
}
.notification-item .fa-info-circle {
  color: #005dab;
}
.notification-item .fa-exclamation-triangle {
  color: #f1bb1c;
}
.notification-item .col-icon {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.notification-list-page {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 15rem;
}
.notification-list-page .btn-mark-read {
  padding: 0 !important;
}
